import { render, staticRenderFns } from "./Index3.vue?vue&type=template&id=056dd57c&scoped=true"
import script from "./Index3.vue?vue&type=script&lang=js"
export * from "./Index3.vue?vue&type=script&lang=js"
import style0 from "../assets/css/common.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "../assets/css/index3.css?vue&type=style&index=1&prod&lang=css&external"


/* normalize component */
import normalizer from "!../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "056dd57c",
  null
  
)

export default component.exports