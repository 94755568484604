<template>
  <div class="main">
    <a-layout class="mlayout">
      <a-layout>
        <a-layout-content class="indexLayoutContent" style="padding: 0px">
          <div class="page flex-col justify-between">
            <div class="group_1 flex-col">
              <div class="section_49 flex-row">
                <div class="image-wrapper_50 flex-col">
                  <img
                    class="label_7"
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngfa5119a1cda8b10941dec3a0c4f8e5d5806ee374943f5aa63c0715d36adfb497"
                  />
                </div>
                <span class="text_215">趣野风向</span>
                <span class="text_216">无限好物</span>
                <span class="text_217">趣野展会</span>
                <span class="text_218">趣野CLUB</span>
                <span class="text_219">趣野论坛</span>
                <span class="text_220">关于我们</span>
                <div class="text-wrapper_73">
                  <span class="text_221">EN</span>
                  <span class="text_222">｜中</span>
                </div>
                <div class="box_106 flex-col justify-end">
                  <div class="section_50 flex-col"></div>
                  <img
                    class="thumbnail_163"
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngbe929d579318cd766eca867beff3a5249c9002b47ff5fbf3e0ebf4b768035288"
                  />
                </div>
              </div>
              <img
                class="image_185"
                referrerpolicy="no-referrer"
                src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngaea29351e554c8f413ec00717691fa950074bad24a43864efac736ad83b0d421"
              />
              <div class="image-wrapper_51 flex-row">
                <img
                  class="thumbnail_164"
                  referrerpolicy="no-referrer"
                  src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngb1af9a0ed8070097a553d9099b9666589aed2e990952dadf0dbb3d395bf76e25"
                />
                <img
                  class="thumbnail_165"
                  referrerpolicy="no-referrer"
                  src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngacd3ca0713ade21e4a34a07bca8a6a2d557fba1f6e1145edde283e8015be3168"
                />
                <img
                  class="thumbnail_166"
                  referrerpolicy="no-referrer"
                  src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf5aef75a33a16bc22a9bfd90ea7b00984860850594514b1eda90e6233a78fd48"
                />
                <img
                  class="thumbnail_167"
                  referrerpolicy="no-referrer"
                  src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng3e7d3bac5ed808449f691ee9b7b700835009d65c14a6d4ab7fc937f6c77fb769"
                />
                <img
                  class="thumbnail_168"
                  referrerpolicy="no-referrer"
                  src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng1cda48bd0cc7a2ccf7fc743ee98167e4b427a8d8a85789638d24cc41e2dd0b28"
                />
                <img
                  class="thumbnail_169"
                  referrerpolicy="no-referrer"
                  src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng8246df0968a63681d8a21564924d859808e5dbebee26fce697029a9c952f44d4"
                />
                <img
                  class="thumbnail_170"
                  referrerpolicy="no-referrer"
                  src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngc09f44381ae867ff998c7f822401650777d75ecefa37a55373f7905191dd87b3"
                />
                <img
                  class="thumbnail_171"
                  referrerpolicy="no-referrer"
                  src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng3f495df433e85ad3d91e09c27e40b25c344e0008a801a55f3c0abf8ef7f1ad82"
                />
                <img
                  class="thumbnail_172"
                  referrerpolicy="no-referrer"
                  src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng79c7307442e2f0841c25ca12e5f06c35613369a31246fd3fe5e731e97aa7e327"
                />
              </div>
              <div class="image-wrapper_52 flex-row">
                <img
                  class="thumbnail_173"
                  referrerpolicy="no-referrer"
                  src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng22047b63ff127c0c37399a1f31c29598dcf4b4d24f4430d91147176694ff9449"
                />
                <img
                  class="thumbnail_174"
                  referrerpolicy="no-referrer"
                  src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng5b4f85d0dd4aec02c5a0f6c517aa80c955ef343aab19536c157dfc108e69b0d1"
                />
                <img
                  class="thumbnail_175"
                  referrerpolicy="no-referrer"
                  src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng9e617960d71eba998983aab6a98519d2355d3c0474e7040912e30ccb4c3e8046"
                />
                <img
                  class="thumbnail_176"
                  referrerpolicy="no-referrer"
                  src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngd7e44b0d17bad7fa364a78e24f40dd68cc4be2d3ad0101f1b0732050c3b7c0d2"
                />
              </div>
              <div class="section_16 flex-row">
                <div class="block_49 flex-col justify-between">
                  <span class="text_9">户外生活领跑者</span>
                  <img
                    class="image_2"
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng72dc6037df8e8e7a0edd4d1e6ec479858c18cd94b97abff3bece91282d6873b1"
                  />
                </div>
                <!-- <span class="text_110"
          >我们崇尚热爱户外生活的态度<br /><br />注重人与自然之间的和谐互动<br /><br />鼓励所有人勇于突破自我边界<br /><br />探索属于自己的户外生活方式</span
        > -->
              </div>
              <span class="text_11">Dare&nbsp;To&nbsp;Air</span>
              <img
                class="image_3"
                referrerpolicy="no-referrer"
                src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng1de2bc28db4df526e6c83555244d8442f4cc0e6b4b682f7d213fc47d1f612c19"
              />
            </div>
            <div class="group_4 flex-row justify-end">
              <div class="box_107 flex-col justify-between">
                <span class="paragraph_1"
                  >All&nbsp;Fields趣野无限，一个致力于探索户外生活方式的文化传播交流平台。<br /><br /><br /><br />我们特设「趣野CLUB」、「趣野好物」、「趣野风向」等入口，引领多元户外生活体验、整合各圈层户外品牌、集结户外潮流文化动向，从活动、商品、资讯等多维度，为喜爱户外的人群提供一站式、可持续、个性化的户外生活方式解决方案。<br /><br /><br /><br />和ALL&nbsp;FIELDS&nbsp;趣野无限一起<br /><br />探索户外生活方式的更多可能和无限乐趣</span
                >
                <div class="group_102 flex-row">
                  <div class="image-text_49 flex-row justify-between">
                    <span class="text-group_123">了解更多</span>
                    <img
                      class="image_186"
                      referrerpolicy="no-referrer"
                      src="https://cdn.moheweb.com/vdb/lweb/imgs/bj.png"
                    />
                  </div>
                </div>
              </div>
              <div class="block_3 flex-col">
                <span class="text_12">ALL&nbsp;FIELDS</span>
                <img
                  class="image_5"
                  referrerpolicy="no-referrer"
                  src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng769704c652595e88df1e6ed7f8ae7b17826d2a0c9e203deb9b34321d7844f4e5"
                />
              </div>
            </div>
            <div class="group_5 flex-col">
              <div class="group_6 flex-col">
                <div class="group_7 flex-col">
                  <div class="box_3 flex-col justify-end">
                    <div class="group_103 flex-row">
                      <div class="text-group_124 flex-col justify-between">
                        <span class="text_13">趣野CLUB</span>
                        <span class="text_14">当户外成为生活常态</span>
                      </div>
                      <div class="group_104 flex-row">
                        <div class="image-text_50 flex-row justify-between">
                          <span class="text-group_125">了解更多</span>
                          <img
                            class="image_187"
                            referrerpolicy="no-referrer"
                            src="https://cdn.moheweb.com/vdb/lweb/imgs/wj.png"
                          />
                        </div>
                      </div>
                      <div class="group_105 flex-row">
                        <div class="image-text_51 flex-row justify-between">
                          <span class="text-group_126">报名通道</span>
                          <img
                            class="image_188"
                            referrerpolicy="no-referrer"
                            src="https://cdn.moheweb.com/vdb/lweb/imgs/wj.png"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="group_106 flex-row justify-between">
                      <div class="box_8 flex-col">
                        <div class="image-wrapper_53 flex-row">
                          <img
                            class="image_189"
                            referrerpolicy="no-referrer"
                            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngcf4981646b9a6c1fe0e5b35606a6b33d2ca3d8a2ebe611f67a5c3c2faaa38dab"
                          />
                        </div>
                        <div class="section_51 flex-row">
                          <div class="text-group_127 flex-col justify-between">
                            <span class="text_15"
                              >热爱和乐于分享推广户外运动</span
                            >
                            <span class="text_16"
                              >ALL&nbsp;FIELDS趣野无限&nbsp;2023年度官方体验官</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="box_9 flex-col"></div>
                    </div>
                    <div class="group_107 flex-row justify-between">
                      <div class="group_9 flex-col"></div>
                      <img
                        class="image_190"
                        referrerpolicy="no-referrer"
                        src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngb52135bb106e30bedd70f4d9e62b0c8ca9c129b04dec40b66d55941126bd10cc"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="group_10 flex-col">
              <div class="group_11 flex-col">
                <div class="box_108 flex-row justify-between">
                  <div class="text-group_128 flex-col justify-between">
                    <span class="text_17">趣野展会</span>
                    <span class="text_18"
                      >一起来期待All&nbsp;Fields&nbsp;趣野无限的首次正式亮相</span
                    >
                  </div>
                  <span class="text_19"
                    >All&nbsp;Fields趣野无限联合上海体博会将于2023年11月23-25日在世博展览馆全面露出。<br /><br />展区以出行方式为纽带，串联不同户外生活场景。<br /><br />在这里，我们通过场景化的陈列及布局⽅式，助⼒各类产品将运动休闲与设计美学完美呈现。<br /><br />在这里，我们借助赛事活动、跨界联动、线上线下互动，帮助观众加速融入户外生活圈。<br /><br />在这⾥，我们希望⽤⼀颗热切的⼼，和身边的每一位勇敢探索人生新可能，表达我们的生活主张。</span
                  >
                </div>
                <div class="box_109 flex-row justify-between">
                  <div class="section_52 flex-row">
                    <div class="image-text_52 flex-row justify-between">
                      <span class="text-group_129">展商通道</span>
                      <img
                        class="image_191"
                        referrerpolicy="no-referrer"
                        src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngc27dae6198692b1550d801ab0a754f3fdfd0c287b36a5776f36d823d7cf527a4"
                      />
                    </div>
                  </div>
                  <div class="section_53 flex-row">
                    <div class="image-text_53 flex-row justify-between">
                      <span class="text-group_130">观众通道</span>
                      <img
                        class="image_192"
                        referrerpolicy="no-referrer"
                        src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngc27dae6198692b1550d801ab0a754f3fdfd0c287b36a5776f36d823d7cf527a4"
                      />
                    </div>
                  </div>
                </div>
                <div class="box_110 flex-col">
                  <span class="text_223">趣野无限户外生活特展即将开幕</span>
                  <div class="text-wrapper_74 flex-row justify-between">
                    <span class="text_224">趣野无限户外生活特展即将开幕</span>
                    <span class="text_225">了解详情</span>
                  </div>
                  <img
                    class="image_193"
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngd587e0d9166d904d08f675c3c8790b82d79b7f09d42401ad6d81c2db545d132a"
                  />
                </div>
                <div class="box_111 flex-col">
                  <span class="text_226"
                    >趣野无限户外生活特展即将开幕即将开幕</span
                  >
                  <div class="text-wrapper_75 flex-row justify-between">
                    <span class="text_227">趣野无限户外生活特展即将开幕</span>
                    <span class="text_228">了解详情</span>
                  </div>
                  <img
                    class="image_194"
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngd587e0d9166d904d08f675c3c8790b82d79b7f09d42401ad6d81c2db545d132a"
                  />
                </div>
                <div class="box_112 flex-col">
                  <span class="text_229">趣野无限户外生活特展即将开幕</span>
                  <div class="text-wrapper_76 flex-row justify-between">
                    <span class="text_230">趣野无限户外生活特展即将开幕</span>
                    <span class="text_231">了解详情</span>
                  </div>
                  <img
                    class="image_195"
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngd587e0d9166d904d08f675c3c8790b82d79b7f09d42401ad6d81c2db545d132a"
                  />
                </div>
                <div class="box_113 flex-col">
                  <span class="text_232"
                    >趣野无限户外生活特展即将开幕即将开幕即将开幕</span
                  >
                  <div class="text-wrapper_77 flex-row justify-between">
                    <span class="text_233">趣野无限户外生活特展即将开幕</span>
                    <span class="text_234">了解详情</span>
                  </div>
                  <img
                    class="image_196"
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngd587e0d9166d904d08f675c3c8790b82d79b7f09d42401ad6d81c2db545d132a"
                  />
                </div>
                <div class="box_114 flex-col">
                  <span class="text_235"
                    >趣野无限户外生活特展即将开幕即将开幕</span
                  >
                  <div class="text-wrapper_78 flex-row justify-between">
                    <span class="text_236">趣野无限户外生活特展即将开幕</span>
                    <span class="text_237">了解详情</span>
                  </div>
                  <img
                    class="image_197"
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngd587e0d9166d904d08f675c3c8790b82d79b7f09d42401ad6d81c2db545d132a"
                  />
                </div>
                <div class="box_115 flex-col">
                  <span class="text_238">趣野无限户外生活特展即将开幕</span>
                  <div class="text-wrapper_79 flex-row justify-between">
                    <span class="text_239">趣野无限户外生活特展即将开幕</span>
                    <span class="text_240">了解详情</span>
                  </div>
                  <img
                    class="image_198"
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngd587e0d9166d904d08f675c3c8790b82d79b7f09d42401ad6d81c2db545d132a"
                  />
                </div>
                <div class="box_116 flex-row">
                  <div class="image-text_54 flex-row justify-between">
                    <span class="text-group_131">了解更多</span>
                    <img
                      class="image_199"
                      referrerpolicy="no-referrer"
                      src="https://cdn.moheweb.com/vdb/lweb/imgs/wj.png"
                    />
                  </div>
                </div>
                <img
                  class="image_19"
                  referrerpolicy="no-referrer"
                  src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng1417c66cc36225e58946166420226a539b36fd85bba39c916f4df65440543a3b"
                />
              </div>
            </div>
            <div class="group_21 flex-row">
              <div class="group_108 flex-col">
                <span class="text_38">无限好物</span>
                <span class="text_39">All&nbsp;Fields让你「闭眼」购物~</span>
                <div class="group_109 flex-row justify-between">
                  <img
                    class="image_20"
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngdde69928532a96535812c2ea044002ee4c914f4661ba9730702537ef75208b2a"
                  />
                  <div class="block_50 flex-col">
                    <span class="text_40">露营帐篷</span>
                    <span class="text_41"
                      >圆顶形帐篷比入门稍微高级点的帐篷，通常功能包含防雨、防晒、防风、透气和隔温等功能。选择时注意露营的天气、活动的环境来选择帐篷的款式和布料材质、内部空间的大小等。</span
                    >
                    <span class="text_42">¥280.00</span>
                    <div class="block_51 flex-row">
                      <div class="image-text_55 flex-row justify-between">
                        <span class="text-group_132">立即购买</span>
                        <img
                          class="image_200"
                          referrerpolicy="no-referrer"
                          src="https://cdn.moheweb.com/vdb/lweb/imgs/bj.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="group_26 flex-col">
                <div class="section_54 flex-row">
                  <div class="box_117 flex-row">
                    <div class="image-text_56 flex-row justify-between">
                      <span class="text-group_133">更多好物</span>
                      <img
                        class="image_201"
                        referrerpolicy="no-referrer"
                        src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnge32c7506621dd1b54fbd0b31ad95d243da6b83a6e46b9c1f86245cb2e1291d70"
                      />
                    </div>
                  </div>
                </div>
                <div class="section_55 flex-row justify-between">
                  <div class="image-wrapper_5 flex-col">
                    <img
                      class="image_23"
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng81ae784b37b3b36d82e141ae7384d940f2f98c67d8b3fa590ba2a6112ba9fd65"
                    />
                  </div>
                  <img
                    class="image_24"
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng17e645ae0a654520fee663bb97bcaf641e76ca38e73e2ddd421ae1f8c26dcd73"
                  />
                </div>
              </div>
              <img
                class="image_25"
                referrerpolicy="no-referrer"
                src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngc47f7978ea2aac57589a0e8f720daca1fa432d758440dcb029154bb30231306d"
              />
            </div>
            <div class="group_28 flex-col">
              <div class="text-group_134 flex-col justify-between">
                <span class="text_43">趣野朋友圈</span>
                <span class="text_44"
                  >听说……这些都是All&nbsp;Fields的好朋友</span
                >
              </div>
              <img
                class="image_26"
                referrerpolicy="no-referrer"
                src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngbab1d2e9d7cec58066ae51a1bc0f2e59a1f95e7bf85dd0d1f46b5826b7e7a9c8"
              />
              <div class="box_118 flex-row justify-between">
                <span class="text_45"
                  >户外博主们的日常生活是怎么样的？<br />户外品牌主理人每天都在忙什么？<br />户外专业大咖是怎样练成的？</span
                >
                <div class="box_119 flex-row">
                  <span class="text_241">探索</span>
                  <img
                    class="image_202"
                    referrerpolicy="no-referrer"
                    src="https://cdn.moheweb.com/vdb/lweb/imgs/bj.png"
                  />
                </div>
              </div>
              <div class="box_120 flex-row">
                <div class="group_73 flex-col">
                  <div class="group_32 flex-col">
                    <div class="image-wrapper_54 flex-row">
                      <img
                        class="image_203"
                        referrerpolicy="no-referrer"
                        src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngcf4981646b9a6c1fe0e5b35606a6b33d2ca3d8a2ebe611f67a5c3c2faaa38dab"
                      />
                    </div>
                    <div class="box_121 flex-row justify-between">
                      <div class="text-group_135 flex-col justify-between">
                        <span class="text_47">David</span>
                        <span class="text_48">Outdoor&nbsp;camping</span>
                      </div>
                      <img
                        class="image_204"
                        referrerpolicy="no-referrer"
                        src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng98ef087a7bce256efcb5b009de558425f48d91c60bc5a9490f1fc01df356b7c0"
                      />
                    </div>
                  </div>
                </div>
                <span class="text_49"
                  >快来这里看看All&nbsp;Fields朋友圈的故事，他们并非一定是你仰望的对象，而是身边的你我他。</span
                >
                <div class="group_74 flex-col"></div>
              </div>
              <span class="text_50">Circle&nbsp;Zoom</span>
              <img
                class="image_31"
                referrerpolicy="no-referrer"
                src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng20b2cffbff0cab5924c06bf1fb1b2840e3b5136f204bb56d0129193021d47d4c"
              />
              <div class="box_122 flex-row justify-between">
                <img
                  class="image_205"
                  referrerpolicy="no-referrer"
                  src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng98ef087a7bce256efcb5b009de558425f48d91c60bc5a9490f1fc01df356b7c0"
                />
                <div class="box_123 flex-row justify-between">
                  <div class="image-wrapper_7 flex-col">
                    <img
                      class="image_33"
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng276d860753a1e3c6c10719342b60fb6075d9065e018c5f012635087e2a58f5aa"
                    />
                  </div>
                  <div class="image-wrapper_8 flex-col">
                    <img
                      class="image_34"
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng0831474b607c92377f97cdb406f49be2b672c0c45ee1a9c838e3fda86d77d91d"
                    />
                  </div>
                  <div class="image-wrapper_9 flex-col">
                    <img
                      class="image_35"
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf66d42e0b838460885e5f791d6467de43e1b2f5e53e01aba277faab5e6049ff1"
                    />
                  </div>
                  <div class="image-wrapper_10 flex-col">
                    <img
                      class="image_36"
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng09875f3f7ff5d000a64480ac8415204e0af2cf0f09dcec41cf866d272dd7a538"
                    />
                  </div>
                  <div class="image-wrapper_11 flex-col">
                    <img
                      class="image_37"
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng4292997c001fa093a90747a80db83ecbd5b8e16aefa99a9e25afea877216fe19"
                    />
                  </div>
                  <div class="image-wrapper_12 flex-col">
                    <img
                      class="image_38"
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnge23058a566ad989215d8a75b9b0d00f8d61d105857499e51c8e3de579cb26b5e"
                    />
                  </div>
                  <div class="image-wrapper_13 flex-col">
                    <img
                      class="image_39"
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng276d860753a1e3c6c10719342b60fb6075d9065e018c5f012635087e2a58f5aa"
                    />
                  </div>
                  <div class="image-wrapper_14 flex-col">
                    <img
                      class="image_40"
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng0831474b607c92377f97cdb406f49be2b672c0c45ee1a9c838e3fda86d77d91d"
                    />
                  </div>
                </div>
              </div>
              <div class="group_77 flex-col"></div>
              <div class="group_78 flex-col"></div>
              <div class="group_35 flex-col"></div>
            </div>
            <div class="group_36 flex-row">
              <div class="box_19 flex-col">
                <img
                  class="image_206"
                  referrerpolicy="no-referrer"
                  src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngcf4981646b9a6c1fe0e5b35606a6b33d2ca3d8a2ebe611f67a5c3c2faaa38dab"
                />
                <span class="text_51"
                  >无论你是户外小白或是发烧友，我们都会以通俗易懂的语言给大众带来专业、好玩、新鲜的户外讯息以及干货。与你分享户外文化，为的是跟大家成为户外趣野好朋友！</span
                >
                <div class="text-group_136 flex-col justify-between">
                  <span class="text_52">出行攻略</span>
                  <span class="text_53">FM.看看世界</span>
                </div>
                <img
                  class="image_207"
                  referrerpolicy="no-referrer"
                  src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng4e043656295421bba5a24fd6b62f055c22039be9e721c037df2d8ce3f02df8ed"
                />
              </div>
              <div class="box_20 flex-col justify-end">
                <span class="text_54"
                  >无论你是户外小白或是发烧友，我们都会以通俗易懂的语言给大众带来专业、好玩、新鲜的户外讯息以及干货。与你分享户外文化，为的是跟大家成为户外趣野好朋友！</span
                >
                <div class="block_6 flex-col">
                  <div class="text-group_137 flex-col justify-between">
                    <span class="text_55">兴趣清单</span>
                    <span class="text_56">趣野故事会</span>
                  </div>
                  <img
                    class="image_208"
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnga3a5b20e0324082a486419c2b193dbce68f3a2cd2b92053dcbe69c15171ccc1e"
                  />
                </div>
              </div>
              <div class="box_21 flex-col">
                <div class="text-group_138 flex-col justify-between">
                  <span class="text_57">趣野风向</span>
                  <span class="text_58">更多视角更多脑洞突破想象</span>
                </div>
                <img
                  class="image_46"
                  referrerpolicy="no-referrer"
                  src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng2c140934e56667fed008900f58df60916bb5bf782e1e50a846fd4000ad950f82"
                />
                <span class="text_59"
                  >涵盖热点趋势、亮点单品、兴趣清单、指南宝典、出行攻略等干货内容，毫不吝啬与你分享户外文化，并释出All&nbsp;Fields对于可持续环保、乡村振兴、潮流时尚等理念的态度和实践。</span
                >
                <div class="box_124 flex-row">
                  <span class="text_242">探索</span>
                  <img
                    class="image_209"
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng5ead0bee9132576f2b077f96ab56f5597e69bb6ae86511a63ff7ef9d2765b420"
                  />
                </div>
                <div class="block_8 flex-col"></div>
                <div class="text-group_139 flex-col justify-between">
                  <span class="text_57">趣野风向</span>
                  <span class="text_58">更多视角更多脑洞突破想象</span>
                </div>
                <div class="block_9 flex-col"></div>
              </div>
              <div class="box_22 flex-col justify-end">
                <span class="text_61"
                  >无论你是户外小白或是发烧友，我们都会以通俗易懂的语言给大众带来专业、好玩、新鲜的户外讯息以及干货。与你分享户外文化，为的是跟大家成为户外趣野好朋友！</span
                >
                <div class="box_23 flex-col">
                  <div class="text-group_140 flex-col justify-between">
                    <span class="text_62">指南宝典</span>
                    <span class="text_63">趣野视角</span>
                  </div>
                  <img
                    class="image_210"
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnga3a5b20e0324082a486419c2b193dbce68f3a2cd2b92053dcbe69c15171ccc1e"
                  />
                  <div class="text-group_141 flex-col justify-between">
                    <span class="text_62">指南宝典</span>
                    <span class="text_63">趣野视角</span>
                  </div>
                </div>
              </div>
              <img
                class="image_49"
                referrerpolicy="no-referrer"
                src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng8da7caad6c746175ef607326e6e71d724651e8c40269415c09698c8838cea3c5"
              />
            </div>
            <div class="group_38 flex-col">
              <div class="group_110 flex-row justify-end">
                <div class="box_125 flex-col">
                  <div class="text-wrapper_80 flex-row justify-between">
                    <span class="text_64">无限好物</span>
                    <span class="text_65">趣野展会</span>
                    <span class="text_66">趣野CLUB</span>
                    <span class="text_67">趣野论坛</span>
                  </div>
                  <div class="text-wrapper_81 flex-row">
                    <span class="text_68">展会简介</span>
                    <span class="text_69">活动展示</span>
                    <span class="text_70">论坛议题</span>
                  </div>
                  <div class="text-wrapper_82 flex-row">
                    <span class="text_71">展会直达</span>
                    <span class="text_72">活动报名</span>
                    <span class="text_73">报名入口</span>
                  </div>
                  <div class="text-wrapper_83 flex-row">
                    <span class="text_74">展会新闻</span>
                    <span class="text_75">活动打卡互动</span>
                    <span class="text_76">往届论坛</span>
                  </div>
                  <div class="text-wrapper_84 flex-row justify-between">
                    <span class="text_77">观众通道</span>
                    <span class="text_78">体验官招募</span>
                  </div>
                  <span class="text_79">All&nbsp;Fields</span>
                </div>
                <div class="text-wrapper_85 flex-col">
                  <span class="text_80">关于我们</span>
                  <span class="text_81">品牌理念</span>
                  <span class="text_82">品牌简介</span>
                  <span class="text_83">联系方式</span>
                </div>
                <div class="box_25 flex-col">
                  <div class="section_56 flex-row justify-between">
                    <div class="image-wrapper_55 flex-col">
                      <img
                        class="image_211"
                        referrerpolicy="no-referrer"
                        src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng3faf36677cb9834cb390b314b97352641fc8edfc37f478b5127544e4e2ba0111"
                      />
                    </div>
                    <span class="text_84">趣野风向</span>
                  </div>
                  <div class="section_57 flex-row">
                    <img
                      class="thumbnail_177"
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng6ca4c41b138a9e6d16a322fb736fdc774ea9428bbdef1ed8b244eb6e5f9a678b"
                    />
                    <img
                      class="thumbnail_178"
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf48e829122d0c2c2df5ea3ee2d2fff766e6f7391b827054cf56beb0cc82a3e29"
                    />
                    <img
                      class="thumbnail_179"
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng03e32edfd56821153b052468cef14bb2288f445bdd00ce0a8b500e7d01fe7d95"
                    />
                    <img
                      class="thumbnail_180"
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng2e1cdf7a1fb6a1429d829eb3ea59422ff7819c1bdce142194c21a92bbe1b88f4"
                    />
                    <img
                      class="thumbnail_181"
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng575b7f33a76c717b6d12823aba2476f8a01187b1edd08f7cc7d28604398a887c"
                    />
                    <img
                      class="thumbnail_182"
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng3b1352c7e8237b2fd8d7c52c156f85904689a53591c641dd0cdf0aab03a3fb1d"
                    />
                    <img
                      class="thumbnail_183"
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng4bd6a185e1b65a3e0419d182be74cf0210faaf70709d1ee7e742c60b587e3fca"
                    />
                    <img
                      class="thumbnail_184"
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngb72c359259419ee80f587b54b4172156bfd152fedf05f4b5b15b1a7e2b570cab"
                    />
                    <img
                      class="thumbnail_185"
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf78ad2e2485e1dc065dc7da0c13bfe36bc63cbb12d4718d355307e52f0ec0d8c"
                    />
                    <span class="text_85">趣野资讯</span>
                  </div>
                  <div class="section_58 flex-row">
                    <img
                      class="thumbnail_186"
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngb014577cd1b56f15bcb8a7fb825844d7e24af5d739831ff38ebf3e465f039fc6"
                    />
                    <img
                      class="thumbnail_187"
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnge2989a289c00018666a739444a2344432f4fa08c4df1beb2a02c89c4a916c9da"
                    />
                    <img
                      class="thumbnail_188"
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng958661c8fc850011405cbaf46a74408ed7411a244a590757b82f4cd3d6d43d6e"
                    />
                    <img
                      class="thumbnail_189"
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngdebaf129e7d25b48789fda8f6c6d007278e38f1f285ea215e9573ab243a4ee96"
                    />
                    <span class="text_86">趣野视角</span>
                  </div>
                  <img
                    class="image_51"
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngd587e0d9166d904d08f675c3c8790b82d79b7f09d42401ad6d81c2db545d132a"
                  />
                </div>
              </div>
              <div class="text-wrapper_86 flex-row">
                <span class="text_87"
                  >2023&nbsp;产品版权所有&nbsp;保留所有权利</span
                >
                <span class="text_88">隐私政策</span>
                <span class="text_89">服务条款</span>
              </div>
            </div>
          </div>

          
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
import {
  fetchClass,
  fetchCase,
  fetchFriend,
  fetchBase,
  fetchAwards,
} from "../api/index";
import FooterIndex from "../components/FooterIndex.vue";
// const Base64 = require('js-base64').Base64
import Swiper, {
  FreeMode,
  Autoplay,
  Effect,
  Pagination,
  Mousewheel,
  Scrollbar
} from "swiper";
Swiper.use([FreeMode, Autoplay, Effect, Pagination, Mousewheel,Scrollbar]);
import "swiper/swiper-bundle.css";
import global from "../common";
import $ from "jquery";
export default {
  name: "index",
  components: {
    FooterIndex,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      lag: "",
      w750: 0,
      m_group_2_height: 0,
      basedata: [],
      frienddata: [],
      frienddata2: [],
      ppswiperindex: 0,
      nopplistshow: 0,
      baseUrl: global.baseUrl,
      thisclass1: 68,
      thisclass2: "",

      classlist: [],

      class2list: [],

      swiper: null,

      swiper2: null,

      swiper3: null,

      selectIndex: 0,

      barclass: "closethis",
      pageWidth: 1920,
      topbarOpacity: 0,
      gtitle: "整合内容营销&nbsp;+&nbsp;智能数据平台&nbsp;+&nbsp;奢侈品营销",
      gdesc:
        "IPmotion（上海派志）作为一类以独特创意DNA驱动的公司，而区别于一般的“广告创意公司”。<br />成立十多年来，IPmotion（上海派志）在人工智能、IT电子、医药、汽车等垂直领域积累了丰富的经验案例，并助力企业、品牌的营销转型和数字转型的全过程。为全球500强与国内行业领先品牌，如：智能科技类企业：世界人工智能大会、世界制造业大会、华为、远景集团、医药类企业：阿斯利康、基石药业、BD医疗、奢侈品类企业：香奈儿、MHD、帕玛强尼等提供全案创意营销代理服务和线下解决方案。",
      mkinds: [],
      ppindex: -1,
      pplist: [],
      bselectindex: -1,
      blinks: [
        { title: "", url: "", class: "jianjie" },
        { title: "", url: "", class: "huoban" },
        { title: "", url: "", class: "fuwuneirong" },
        { title: "", url: "", class: "anli" },
        { title: "", url: "", class: "lianxiwomen" },
        { title: "", url: "ai", class: "" },
      ],
      sectionindex: 0,
      sections: [],
      chsections: [
        {
          img: "i-logo-0.png",
          title: "展览展厅&amp;活动",
          desc: "提供前沿的创意理念，及线下制作的一体化服务",
          tip: ["临时展厅", "常设展厅", "多媒体互动", "交互内容", "线下活动"],
        },
        {
          img: "i-logo-1.png",
          title: "智能平台&amp;多媒体互动",
          desc: "提供服务与解决方案的智能科技应用平台",
          tip: ["数字营销", "社会化传播", "TVC视频", "宣传片"],
        },
        {
          img: "i-logo-2.png",
          title: "智能平台&amp;多媒体互动",
          desc: "提供服务与解决方案的智能科技应用平台",
          tip: ["智能会务系统", "智能云展", "数字孪生", "智能机器人"],
        },
        {
          img: "i-logo-3.png",
          title: "奢侈品营销",
          desc: "结合线下制作、运营于一体的互动体验",
          tip: ["促销活动", "体验营销"],
        },
      ],
      ensections: [
        {
          img: "i-logo-0.png",
          title: "Exhibition & Event",
          desc: "Provide cutting-edge creative concepts and integrated services for offline production",
          tip: ["Exhibition", "Muti-Media", "Interactive", "Physical Event"],
        },
        {
          img: "i-logo-1.png",
          title: "Digital Marketing & <br />Video Creation",
          desc: "Covering both online and offline,Integrate omnichannel promotion and dissemination",
          tip: [
            "Digital marketing",
            "Social Communication",
            "TVC Video",
            "Promotional video",
          ],
        },
        {
          img: "i-logo-2.png",
          title: "Intelligence Platform & <br />Multi Interactive",
          desc: "An intelligent technology application platform that provides services and solutions",
          tip: [
            "Intelligent conference system",
            "Intelligent Virtual Event",
            "Digital twin",
            "intelligent robot",
          ],
        },
        {
          img: "i-logo-3.png",
          title: "Luxury",
          desc: "Interactive experience combining offline production and operation",
          tip: ["Promotional activities", "Experience Marketing"],
        },
      ],
      data: [],
      dataloading: 0,
      pageSize: 12,
      query: {
        class1: 68,
        class2: [],
      },
      alive: true,
      adata: [],
      awardsindex: 0,
    };
  },

  activated() {
    let that = this;
    console.log("onActivated");
    let scrollvalue = global.getIndexscroll();
    if (scrollvalue > 0) {
      $(".main").scrollTop(scrollvalue);
    }

    if (scrollvalue > 400) {
      this.topbarOpacity = (scrollvalue - 400) / 200;
      if (this.topbarOpacity > 1) {
        this.topbarOpacity = 1;
      }
    } else {
      this.topbarOpacity = 0;
    }

    that.resizepage();

    setTimeout(() => {
      let classtext = global.getIndexDclass();
      // console.log("classtext:", classtext);
      if (classtext != "") {
        that.goDiv(classtext, "");
      }
      // let video0 = document.getElementById("video0");
      // video0.play();
    }, 1000);
    this.alive = true;
    this.handleScroll;

    // if (that.w750 == 0) {
    //     if (that.swiper2) {
    //       that.swiper2.destroy(false, true);
    //     }
    //     that.myswiper2();
    //   } else {
    //     if (that.swiper2) {
    //       that.swiper2.destroy(false, true);
    //     }
    //     that.myswiper2m();
    //   }
  },
  deactivated() {
    console.log("deactivated:");
    this.alive = false;
  },

  mounted() {
    let that = this;

    let lag = this.$route.params.lag;

    if (lag) {
      this.lag = lag;
    }

    console.log("this.lag:", this.lag);

    if (this.lag == "en") {
      this.sections = this.ensections;
    } else {
      this.sections = this.chsections;
    }
    global.setIndexDclass("");
    global.setIndexscroll(0);
    this.handleScroll;
    that.resizepage();
    window.addEventListener("scroll", this.handleScroll, true);
    window.addEventListener("resize", this.handleResize, true);
    this.myswiper();
    this.myswiper3();
    setTimeout(() => {
      $(document).on("click", ".closesw", function () {
        $(".showppdiv").fadeTo(300, 0, () => {
          $(".showppdiv").css({ left: "-100%" });
        });
      });
      $(document).on("click", ".closesw3", function () {
        $(".showawardsdiv").fadeTo(300, 0, () => {
          $(".showawardsdiv").css({ left: "-100%" });
        });
      });
    }, 1000);
    that.getClassList();
    that.fetch();
    that.fetchf();
    that.fetchb();
    that.fetcha();
  },
  beforeCreate() {
    let that = this;
  },
  methods: {
    windowopen(url) {
      if (url != "") {
        window.open(url);
      }
    },
    fetch(
      params = { show: 1, hot: 1, pagesize: this.pageSize, query: this.query }
    ) {
      this.ppswiperindex = 0;
      let that = this;
      this.loading = true;

      fetchCase(params).then((res) => {
        // console.log("fetchCase:", res);
        const pagination = { ...that.pagination };
        pagination.total = parseInt(res.results.total);
        pagination.pageSize = that.pageSize;
        that.loading = false;

        if (res.results.list) {
          that.data = res.results.list;
          that.nopplistshow = 0;
        } else {
          that.nopplistshow = 1;
        }
        that.dataloading = 0;
        that.pagination = pagination;
        // console.log("data:", that.data);
        // setTimeout(() => {
        //   that.swiper.update();
        // }, 100);
      });
    },
    fetchf(params = { show: 1, pagesize: 1000 }) {
      let that = this;
      this.loading = true;

      fetchFriend(params).then((res) => {
        // console.log("fetchFriend:", res);
        const pagination = { ...that.pagination };
        pagination.total = parseInt(res.results.total);
        pagination.pageSize = that.pageSize;
        that.loading = false;

        that.frienddata = res.results.list;

        let frienddata2 = [];

        let lindex = 0;
        res.results.list.map((item, index) => {
          if (index % 4 == 0) {
            frienddata2[lindex] = [];
          }
          frienddata2[lindex].push(item);
          if (index % 4 == 3) {
            lindex++;
          }
        });
        that.frienddata2 = frienddata2;

        // console.log("frienddata:", that.frienddata);
        // console.log("frienddata2:", that.frienddata2);

        if (that.w750 == 0) {
          that.myswiper2();
        } else {
          that.myswiper2m();
        }
      });
    },
    fetcha(params = { show: 1, hot: 1, pagesize: this.pageSize }) {
      this.ppswiperindex = 0;
      let that = this;
      this.loading = true;

      fetchAwards(params).then((res) => {
        console.log("fetchAwards:", res);
        const pagination = { ...that.pagination };
        pagination.total = parseInt(res.results.total);
        pagination.pageSize = that.pageSize;

        if (res.results.list) {
          that.adata = res.results.list;
        } else {
        }
        that.pagination = pagination;
        // console.log("data:", that.data);
        // setTimeout(() => {
        //   that.swiper3.update();
        // }, 100);
      });
    },

    fetchb(params = {}) {
      let that = this;
      this.loading = true;

      fetchBase(params).then((res) => {
        // console.log("fetchBase:", res.results);
        that.loading = false;
        that.basedata = res.results;

        that.blinks[0].title = res.results.t0;
        that.blinks[1].title = res.results.t1;
        that.blinks[2].title = res.results.t2;
        that.blinks[3].title = res.results.t3;
        that.blinks[4].title = res.results.t4;
        that.blinks[5].title = res.results.t5;
        // that.blinks[6].title = res.results.t6;
        // that.blinks[7].title = res.results.t7;
        // that.blinks[8].title = res.results.t8;
        // that.blinks[9].title = res.results.t9;
        // that.blinks[10].title = res.results.t10;

        if (that.lag == "en") {
          that.blinks[0].title = res.results.ent0;
          that.blinks[1].title = res.results.ent1;
          that.blinks[2].title = res.results.ent2;
          that.blinks[3].title = res.results.ent3;
          that.blinks[4].title = res.results.ent4;
          that.blinks[5].title = res.results.ent5;
          // that.blinks[6].title = res.results.ent6;
          // that.blinks[7].title = res.results.ent7;
          // that.blinks[8].title = res.results.ent8;
          // that.blinks[9].title = res.results.ent9;
          // that.blinks[10].title = res.results.ent10;

          that.basedata.t0 = res.results.ent0;
          that.basedata.t1 = res.results.ent1;
          that.basedata.t2 = res.results.ent2;
          that.basedata.t3 = res.results.ent3;
          that.basedata.t4 = res.results.ent4;
          that.basedata.t45 = res.results.ent45;
          that.basedata.t5 = res.results.ent5;
          that.basedata.t6 = res.results.ent6;
          that.basedata.t7 = res.results.ent7;
          that.basedata.t8 = res.results.ent8;
          that.basedata.t9 = res.results.ent9;
          that.basedata.t10 = res.results.ent10;

          that.basedata.tel = res.results.entel;
          that.basedata.adr = res.results.enadr;
          that.basedata.email = res.results.enemail;
          that.basedata.mtitle = res.results.enmtitle;
          that.basedata.mcontent = res.results.enmcontent;
          that.basedata.aititle = res.results.enaititle;
          that.basedata.aicontent = res.results.enaicontent;
          that.basedata.aicontent0 = res.results.enaicontent0;
          that.basedata.aicontent1 = res.results.enaicontent1;
          that.basedata.aicontent2 = res.results.enaicontent2;
          that.basedata.aicontent3 = res.results.enaicontent3;
          that.basedata.aicontent4 = res.results.enaicontent4;
        }
      });
    },

    selectclass2(id) {
      this.nopplistshow = 0;
      this.dataloading = 1;
      this.thisclass2 = id;
      // console.log(id);
      this.query = {
        class1: this.thisclass1,
        class2: [id],
      };
      this.data = [];
      setTimeout(() => {
        this.fetch();
      }, 500);
    },
    getClassList() {
      const that = this;
      fetchClass().then((res) => {
        // console.log(
        //   "%cfetchClass",
        //   "color: green;font-size: 14px;font-weight: bold;",
        //   res
        // );
        that.classlist = res.results.list;

        // console.log("classlist:", that.classlist);

        that.class2list = this.classlist.filter((it) => {
          return this.thisclass1 == it.id;
        })[0]["list"];

        // console.log("class2list:", that.class2list);

        let mkinds = [];

        that.class2list.map((item) => {
          let cname = item.cname;
          if (that.lag == "en") {
            cname = item.encname;
          }
          mkinds.push({ name: cname, id: item.id });
        });

        that.mkinds = mkinds;

        console.log("mkinds:", this.mkinds);
      });
    },

    swSlideTo(index, id) {
      // alert(this.w750);

      if (this.w750 == 0) {
      } else if (this.w750 == 1) {
        if (this.lag == "en") {
          this.$router.push(`/pdetall/${d}/en`);
        } else {
          this.$router.push(`/pdetall/${id}`);
        }
        return false;
      }
      let that = this;
      this.swiper.slideTo(index, 0);
      $(".showppdiv").css({ left: "0%" });
      $(".showppdiv").fadeTo(300, 1, () => {});
    },

    sw3SlideTo(index, id) {
      console.log(index, id);

      if (this.w750 == 0) {
      } else if (this.w750 == 1) {
        if (this.lag == "en") {
          this.$router.push(`/adetall/${d}/en`);
        } else {
          this.$router.push(`/adetall/${id}`);
        }
        return false;
      }
      let that = this;
      this.swiper3.slideTo(index, 0);
      $(".showawardsdiv").css({ left: "0%" });
      $(".showawardsdiv").fadeTo(300, 1, () => {});
    },
    gowebsite(url) {
      window.open(url);
    },
    myswiper() {
      let that = this;

      // setTimeout(() => {
      //   this.swiper = new Swiper(".sc", {
      //     duration: 1000,
      //     pagination: {
      //       // el: '.swiper-pagination',
      //     },
      //     navigation: {
      //       nextEl: ".swiper-button-next",
      //       prevEl: ".swiper-button-prev",
      //     },
      //     on: {
      //       transitionEnd: function () {
      //         that.selectIndex = this.activeIndex;
      //         // console.log(that.selectIndex);
      //       },
      //       init: function (swiper) {
      //         this.emit("transitionEnd"); //在初始化时触发一次transitionEnd事件，需要先设置transitionEnd
      //       },
      //     },
      //   });
      // }, 10);
    },
    myswiper3() {
      let that = this;

      // setTimeout(() => {
      //   that.swiper3 = new Swiper(".sc3", {
      //     duration: 1000,
      //     pagination: {
      //       // el: '.swiper-pagination',
      //     },
      //     navigation: {
      //       nextEl: ".swiper-button-next",
      //       prevEl: ".swiper-button-prev",
      //     },
      //     on: {
      //       transitionEnd: function () {
      //         that.selectIndex = this.activeIndex;
      //         // console.log(that.selectIndex);
      //       },
      //       init: function (swiper) {
      //         this.emit("transitionEnd"); //在初始化时触发一次transitionEnd事件，需要先设置transitionEnd
      //       },
      //     },
      //   });
      // }, 10);
    },

    myswiper2() {
      let that = this;

      // setTimeout(() => {
      //   this.swiper2 = new Swiper(".sc2", {
      //     speed: 2000,
      //     slidesPerView: 5,
      //     slidesPerColumn: 1,
      //     spaceBetween: 0,
      //     // freeMode: true,
      //     loop: true,
      //     autoplay: {
      //       delay: 4000,
      //       disableOnInteraction: false,
      //     },
      //     pagination: {
      //       el: ".swiper-pagination",
      //       clickable: true,
      //     },
      //   });
      // }, 10);
    },

    myswiper2m() {
      let that = this;

      // setTimeout(() => {
      //   this.swiper2 = new Swiper(".sc2", {
      //     speed: 2000,
      //     slidesPerView: 2,
      //     slidesPerColumn: 1,
      //     spaceBetween: 0,
      //     // freeMode: true,
      //     loop: true,
      //     autoplay: {
      //       delay: 4000,
      //       disableOnInteraction: false,
      //     },
      //     pagination: {
      //       el: ".swiper-pagination",
      //       clickable: true,
      //     },
      //   });
      // }, 10);
    },

    openBardiv() {
      this.barclass = "openthis";
    },
    closeBardiv() {
      this.barclass = "closethis";
    },
    handleResize() {
      this.resizepage();
    },
    resizepage() {
      let that = this;
      this.pageWidth = window.innerWidth;
      let pageHeight = window.innerHeight;
      if (window.innerWidth > 1920) {
        this.pageWidth = 1920;
      }
      if (window.innerWidth <= 750) {
        this.sectionindex = 0;
        this.m_group_2_height = pageHeight;
        this.w750 = 1;
      } else {
        this.m_group_2_height = 0;
        this.w750 = 0;
      }
      // console.log("resize", this.pageWidth);
      // setTimeout(() => {
      $("html").css({ fontSize: this.pageWidth / 192 });
      // }, 500);

      // setTimeout(() => {
      //   that.resizepage();
      // },100);

      if (that.w750 == 0) {
        // if (that.swiper2) {
        //   that.swiper2.destroy(false, true);
        // }
        that.myswiper2();
      } else {
        if (that.swiper2) {
          that.swiper2.destroy(false, true);
        }
        that.myswiper2m();
      }
    },
    handleScroll(e) {
      if (this.alive) {
        const winHeight =
          e.target.scrollTop || document.documentElement.scrollTop;
        global.setIndexscroll(winHeight);
        if (winHeight > 400) {
          this.topbarOpacity = (winHeight - 400) / 200;
          if (this.topbarOpacity > 1) {
            this.topbarOpacity = 1;
          }
        } else {
          this.topbarOpacity = 0;
        }
      }
    },
    changelag(lag) {
      if (lag == "en") {
        this.$router.push("index/en");
        setTimeout(() => {
          location.reload();
        }, 100);
      } else {
        this.$router.push("/index");
        setTimeout(() => {
          location.reload();
        }, 100);
      }
    },
    goDiv(classtext, url) {
      this.barclass = "closethis";
      if (url != "") {
        global.setIndexscroll(0);
        if (this.lag == "en") {
          url = `/${url}/en`;
        }
        this.$router.push(url);
        return false;
      }
      // 获取元素在页面中的位置
      // console.log(this.$refs.dingyue_box_ref.getBoundingClientRect());

      // document
      //   .getElementById("agentTitle")
      //   .scrollIntoView({ block: "start", behavior: "smooth" });
      // 跳转到指定位置并且平滑滚动
      // console.log(this.$el.querySelector(`.${classtext}`));
      this.$el
        .querySelector(`.${classtext}`)
        .scrollIntoView({ block: "start", behavior: "smooth" });

      global.setIndexDclass("");
    },
    blinkshandleHover(index) {
      this.bselectindex = index;
      // console.log(this.bselectindex);
    },
    sectionhandleHover(index) {
      if (this.w750 == 0) this.sectionindex = index;
    },
    blinkshandleLeave() {
      this.bselectindex = -1;
    },
    pphandleHover(index) {
      this.ppindex = index;
    },
    pphandleLeave() {},

    awardshandleHover(index) {
      this.awardsindex = index;
    },
    awardshandleLeave() {
      this.awardsindex = -1;
    },
  },
};
</script>


<style src="../assets/css/common.css" />
<style src="../assets/css/index3.css" />

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
